import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import BlogStats from './BlogStats'
import BlogInfo from './BlogInfo'
import Audits from './Audits'
import LookerStudio from './BlogLookerStudio'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

const tabs = [
  { label: 'Info', value: 'info' },
  { label: 'Stats', value: 'stats' },
  { label: 'Reporting', value: 'reporting' },
  { label: 'Audits', value: 'audits' }
]

const Blog: React.FC = () => {
  const params = useParams()
  const blogId = Number(params.id)
  const [tab, setTab] = React.useState(tabs[0].value)

  return (
    <div>
      <PageHeaderText>Blog #{blogId}</PageHeaderText>
      <Box display="flex" padding={4}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="basic tabs example">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          {tab === 'info' && (
            <Box marginTop={2}>
              <BlogInfo blogId={blogId} />
            </Box>
          )}
          {tab === 'stats' && (
            <Box marginTop={2}>
              <BlogStats blogId={blogId} />
            </Box>
          )}
          {tab === 'reporting' && (
            <Box marginTop={2}>
              <LookerStudio blogId={blogId} />
            </Box>
          )}
          {tab === 'audits' && (
            <Box marginTop={2}>
              <Audits blogId={blogId} />
            </Box>
          )}
        </Stack>
      </Box>
    </div>
  )
}

export default Blog
