import instance from './client'
import { type AI_PROVIDER, type BaseError, type Template, type TemplatesUpdateData } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

export const useTemplatesByProvider = (provider: AI_PROVIDER): UseQueryResult<Template[], BaseError> => {
  return useQuery<unknown, BaseError, Template[]>({
    queryKey: ['templates-by-provider', provider],
    queryFn: async () => await instance.get('/templates/provider/' + provider).then((data) => data.data),
    enabled: !!provider
  })
}

export const useTemplateByProvider = (key: string, provider: AI_PROVIDER): UseQueryResult<Template, BaseError> => {
  return useQuery<unknown, BaseError, Template>({
    queryKey: ['template-by-provider', provider, key],
    queryFn: async () => await instance.get(`/templates/provider/${provider}/${key}`).then((data) => data.data),
    enabled: !!provider && !!key
  })
}

export const useUpdateTemplatesByProvider = (provider: AI_PROVIDER): UseMutationResult<Template[], AxiosError, TemplatesUpdateData> => {
  const queryClient = useQueryClient()
  return useMutation<Template[], AxiosError, TemplatesUpdateData>({
    mutationKey: ['update-templates-by-provider', provider],
    mutationFn: async (input) => await instance.patch(`/templates/provider/${provider}`, { data: input }).then((response) => response.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['templates-by-provider', provider])
      ;(data as any)?.data?.forEach((item: Template) => {
        queryClient.invalidateQueries(['template-by-provider', provider, item.key])
      })
    }
  })
}
