import React, { useContext, useMemo, useState } from 'react'
import Empty from '../Empty/Empty'
import { Box, Button, Typography, TextField, Autocomplete, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useInitNewLighthouseAudit, useLighthouseAuditsList } from '../../api/audit'
import Spinner from '../../components/spinner/Spinner'
import type { AuditLighthouseItem } from '../../types'
import MessageContext from '../../contexts/MessageContext'
import { locale } from '../../constants'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

enum Colors {
  Inherit = 'inherit',
  GrayF5 = '#f5f5f5',
  GrayF0 = '#f0f0f0',
  GrayF9 = '#f9f9f9',
  GrayFA = '#fafafa',
  GrayEF = '#efefef'
}

function parseNumber(val: string | number | undefined): number | null {
  if (val === undefined) return null
  if (typeof val === 'number') return val
  const parsed = parseFloat(val)
  return Number.isNaN(parsed) ? null : parsed
}

const METRICS = [
  {
    key: 'performance',
    label: 'Performance',
    description: 'View key metrics (FCP, LCP, TBT, CLS)'
  },
  {
    key: 'accessibility',
    label: 'Accessibility',
    description: 'Compliance with WCAG standards'
  },
  {
    key: 'best-practices',
    label: 'Best Practices',
    description: 'Identify potential security issues'
  },
  {
    key: 'seo',
    label: 'SEO',
    description: 'Highlights SEO readiness'
  },
  {
    key: 'first-contentful-paint',
    label: 'First Contentful Paint',
    description: 'Render time for first visible content',
    isTimeMetric: true
  },
  {
    key: 'largest-contentful-paint',
    label: 'Largest Contentful Paint',
    description: 'Render time for largest visible content',
    isTimeMetric: true
  },
  {
    key: 'total-blocking-time',
    label: 'Total Blocking Time',
    description: 'Time blocked by scripts',
    isTimeMetric: true
  },
  {
    key: 'cumulative-layout-shift',
    label: 'Cumulative Layout Shift',
    description: 'Unexpected layout shifts',
    isTimeMetric: true
  },
  {
    key: 'speed-index',
    label: 'Speed Index',
    description: 'Visual loading experience',
    isTimeMetric: true
  }
]

function getScoreColor(score: number | null | undefined): string {
  if (score === null || score === undefined) return 'inherit'
  if (score >= 0.9) return '#0CCE6B'
  if (score >= 0.5) return '#FFA400'
  return '#FF4E42'
}

function formatDate(dateString: string | undefined): string {
  if (!dateString) return '-'
  const date = new Date(dateString)
  const isoDate = date.toISOString().slice(0, 10)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${isoDate} | ${hours}:${minutes}`
}

interface ScoreCellProps {
  value?: number | null
}

const ScoreCell: React.FC<ScoreCellProps> = ({ value }) => {
  return <TableCell sx={{ color: getScoreColor(value), fontWeight: 600 }}>{value ? value * 100 : '-'}</TableCell>
}

interface NestedAuditRowProps {
  item: AuditLighthouseItem
}

const NestedAuditRow: React.FC<NestedAuditRowProps> = ({ item }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: open ? Colors.GrayF5 : Colors.Inherit,
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: open ? Colors.GrayF0 : Colors.GrayF9
          }
        }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <strong>{formatDate(item.created_at)}</strong>
        </TableCell>
        <TableCell>
          <strong>{item.url}</strong>
        </TableCell>
        <ScoreCell value={parseNumber(item.assessments?.performance?.desktop)} />
        <ScoreCell value={parseNumber(item.assessments?.accessibility?.desktop)} />
        <ScoreCell value={parseNumber(item.assessments?.['best-practices']?.desktop)} />
        <ScoreCell value={parseNumber(item.assessments?.seo?.desktop)} />
        <ScoreCell value={parseNumber(item.assessments?.performance?.mobile)} />
        <ScoreCell value={parseNumber(item.assessments?.accessibility?.mobile)} />
        <ScoreCell value={parseNumber(item.assessments?.['best-practices']?.mobile)} />
        <ScoreCell value={parseNumber(item.assessments?.seo?.mobile)} />
      </TableRow>

      <TableRow
        sx={{
          backgroundColor: open ? Colors.GrayEF : Colors.Inherit,
          transition: 'background-color 0.2s'
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Metric</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Desktop Score</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Mobile Score</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Details</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {METRICS.map((metric) => {
                    const desktopValueRaw = item.assessments?.[metric.key]?.desktop
                    const mobileValueRaw = item.assessments?.[metric.key]?.mobile
                    const desktopValue = metric.isTimeMetric ? desktopValueRaw : parseNumber(desktopValueRaw)
                    const mobileValue = metric.isTimeMetric ? mobileValueRaw : parseNumber(mobileValueRaw)

                    const DesktopCell = (): any => <TableCell>{desktopValue ?? '-'}</TableCell>
                    const MobileCell = (): any => <TableCell>{mobileValue ?? '-'}</TableCell>

                    const DesktopCellScore = (): any => <ScoreCell value={parseNumber(desktopValueRaw)} />
                    const MobileCellScore = (): any => <ScoreCell value={parseNumber(mobileValueRaw)} />

                    const isTime = !!metric.isTimeMetric
                    return (
                      <TableRow key={metric.key}>
                        <TableCell>{metric.label}</TableCell>
                        {isTime ? <DesktopCell /> : <DesktopCellScore />}
                        {isTime ? <MobileCell /> : <MobileCellScore />}
                        <TableCell>{metric.description}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function getRepresentativeAudit(audits: AuditLighthouseItem[]): AuditLighthouseItem {
  const sorted = [...audits].sort((a, b) => {
    const timeA = a.created_at ? new Date(a.created_at).getTime() : 0
    const timeB = b.created_at ? new Date(b.created_at).getTime() : 0
    return timeB - timeA
  })
  return sorted[0]
}

interface CollapsibleUrlRowProps {
  url: string
  audits: AuditLighthouseItem[]
}

const CollapsibleUrlRow: React.FC<CollapsibleUrlRowProps> = ({ url, audits }) => {
  const [open, setOpen] = useState(false)
  const representativeAudit = getRepresentativeAudit(audits)

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: open ? Colors.GrayFA : Colors.Inherit,
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: open ? Colors.GrayF0 : Colors.GrayF9
          }
        }}
      >
        <TableCell
          sx={{
            width: '1%',
            textAlign: 'center'
          }}
        >
          <IconButton aria-label="expand url row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ paddingLeft: '4px' }}>
          <strong>{representativeAudit.url}</strong>
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          backgroundColor: open ? Colors.GrayEF : Colors.Inherit,
          transition: 'background-color 0.2s'
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Created At</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Performance (D)</TableCell>
                    <TableCell>Accessibility (D)</TableCell>
                    <TableCell>Best Practices (D)</TableCell>
                    <TableCell>SEO (D)</TableCell>
                    <TableCell>Performance (M)</TableCell>
                    <TableCell>Accessibility (M)</TableCell>
                    <TableCell>Best Practices (M)</TableCell>
                    <TableCell>SEO (M)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audits
                    .slice()
                    .sort((a, b) => new Date(b.created_at ?? 0).getTime() - new Date(a.created_at ?? 0).getTime())
                    .map((audit) => (
                      <NestedAuditRow key={audit.id} item={audit} />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function groupByUrl(items: AuditLighthouseItem[]): Record<string, AuditLighthouseItem[]> {
  return items.reduce<Record<string, AuditLighthouseItem[]>>((acc, item) => {
    const { url } = item
    if (!acc[url]) {
      acc[url] = []
    }
    acc[url].push(item)
    return acc
  }, {})
}

interface ListAuditResultProps {
  resultsList: AuditLighthouseItem[] | []
}

const LighthouseAuditList: React.FC<ListAuditResultProps> = ({ resultsList = [] }) => {
  const groupedData = useMemo(() => groupByUrl(resultsList), [resultsList])
  const urls = Object.keys(groupedData)

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {urls.map((url) => (
            <CollapsibleUrlRow key={url} url={url} audits={groupedData[url]} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface AuditsProps {
  blogId: number
  lighthouseAuditsList: AuditLighthouseItem[]
}

const Audits: React.FC<AuditsProps> = ({ blogId, lighthouseAuditsList }) => {
  const addMessage = useContext(MessageContext)
  const initNewLighthouseAudit = useInitNewLighthouseAudit()

  const uniqueUrls = useMemo(() => {
    const urls = lighthouseAuditsList.map((audit) => audit.url)
    return Array.from(new Set(urls))
  }, [lighthouseAuditsList])

  const [selectedUrl, setSelectedUrl] = useState<string | null>(null)

  const handleUrlChange = (_event: any, newValue: string | null): void => {
    if (!newValue) {
      setSelectedUrl('')
    } else {
      setSelectedUrl(newValue)
    }
  }

  const filteredAudits = useMemo(() => {
    if (!selectedUrl || selectedUrl === '') {
      return lighthouseAuditsList
    }
    return lighthouseAuditsList.filter((item) => item.url === selectedUrl)
  }, [selectedUrl, lighthouseAuditsList])

  const handleRerunAudit = (): void => {
    if (!selectedUrl) return
    initNewLighthouseAudit
      .mutateAsync({
        url: selectedUrl,
        blogId: blogId ?? 0
      })
      .then(() => {
        addMessage({ id: Date.now(), message: locale.response.success, severity: 'success' })
      })
      .catch((err) => {
        addMessage({ id: Date.now(), message: err.response.data || err.message })
      })
  }

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4">Lighthouse Audits</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Autocomplete sx={{ width: 300, mr: 2 }} size="small" options={[...uniqueUrls]} value={selectedUrl} onChange={handleUrlChange} renderInput={(params) => <TextField {...params} label="Filter by URL" variant="outlined" />} />

            <Button variant="contained" color="primary" onClick={handleRerunAudit}>
              Run Audit Again
            </Button>
          </Box>
        </Box>

        <LighthouseAuditList resultsList={filteredAudits} />
      </Box>
    </>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  let errorMessage = ''
  const lighthouseAuditsListRequest = useLighthouseAuditsList(blogId)

  if (lighthouseAuditsListRequest.isLoading) {
    return <Spinner marginY={8} />
  }

  if (lighthouseAuditsListRequest.isError) {
    const error = lighthouseAuditsListRequest.error
    errorMessage = (error as any)?.message ?? 'Wrong Blog Id'
  }

  if (lighthouseAuditsListRequest.data) {
    return <Audits blogId={blogId} lighthouseAuditsList={lighthouseAuditsListRequest.data} />
  }

  return <Empty message={errorMessage} />
}

export default Container
