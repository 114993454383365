import React, { useContext } from 'react'
import { useCompanyConfig, useRemoveCompanyConfig, useUpdateCompanyConfig } from '../../api/company'
import MessageContext from '../../contexts/MessageContext'
import { AI_PROVIDER, type CompanyConfig as CompanyConfigType, type CompanyConfigExtended } from '../../types'
import Empty from '../Empty/Empty'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from '../../components/spinner/Spinner'
import CompanyConfigForm from '../../components/companyConfigForm/CompanyConfigForm'

const companyConfigSchema = Yup.object({
  ai_provider: Yup.string().oneOf(Object.values(AI_PROVIDER)).required(),
  ai_model: Yup.string().required(),
  ai_temperature: Yup.number().required().min(0).max(1)
})

interface CompanyConfigProps {
  config: CompanyConfigExtended
}

const CompanyConfig: React.FC<CompanyConfigProps> = ({ config }) => {
  const updateCompanyConfig = useUpdateCompanyConfig()
  const removeCompanyConfig = useRemoveCompanyConfig()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<CompanyConfigType> = useFormik<CompanyConfigType>({
    initialValues: {
      company_id: config.company_id,
      ai_provider: config.ai_provider,
      ai_model: config.ai_model,
      ai_temperature: config.ai_temperature,
      created_at: config.created_at,
      updated_at: config.updated_at
    },
    enableReinitialize: true,
    validationSchema: companyConfigSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateCompanyConfig
        .mutateAsync(values)
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  const handleReset = (): void => {
    formik.setSubmitting(true)
    removeCompanyConfig
      .mutateAsync({ company_id: config.company_id })
      .catch((err) => {
        addMessage({
          id: Date.now(),
          message: err.response?.data || err.message
        })
      })
      .finally(() => {
        formik.setSubmitting(false)
      })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex">
        <Stack spacing={2} width={'100%'}>
          <CompanyConfigForm formik={formik} config={config} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Button disabled={formik.isSubmitting} variant="outlined" color="secondary" style={{ width: 230 }} onClick={ handleReset }>
              {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Reset
            </Button>
            <Button disabled={formik.isSubmitting} type="submit" variant="contained" style={{ width: 230 }}>
              {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Save
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  )
}

const Container: React.FC<{ companyId: number }> = ({ companyId }) => {
  const { data, isLoading, error } = useCompanyConfig(companyId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Company Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <CompanyConfig config={data} />
  return <Empty message={message} />
}
export default Container
