import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { type AuditLighthouseItem, type QueryParams } from '../types'
import instance from './client'

export const useLighthouseAuditsList = (blogId: number): UseQueryResult<AuditLighthouseItem[], AxiosError> => {
  return useQuery<unknown, AxiosError, AuditLighthouseItem[]>({
    queryKey: ['lighthouseAudits', blogId],
    queryFn: async () => await instance.post(`/audit/lighthouse/list/${blogId}`).then((data) => data.data)
  })
}

type AuditLighthouseItemInitReport = {
  message: string
  data: {
    id: number
    status: string
  }
}

type AuditLighthouseInit = {
  blogId: number
  url: string
}

export const useInitNewLighthouseAudit = (): UseMutationResult<AuditLighthouseItemInitReport, AxiosError, AuditLighthouseInit> => {
  const queryClient = useQueryClient()
  return useMutation<AuditLighthouseItemInitReport, AxiosError, AuditLighthouseInit>({
    mutationKey: ['lighthouse-audit-init'],
    mutationFn: async ({ blogId, url }) => await instance.post('/audit/lighthouse/init', { blogId, url }).then((data) => data.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['lighthouseAudits'])
    }
  })
}
