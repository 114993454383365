import React, { useContext, useEffect, useState } from 'react'
import { type FormikHelpers, type FormikProps, useFormik } from 'formik'
import { type UseTemplate } from '../../../../hooks/useTemplate'
import { Box, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { type Template, type TemplatesUpdateData } from '../../../../types'
import TemplateActions from '../TemplateActions/TemplateActions'
import css from './ProviderTemplates.module.css'
import cls from 'classnames'
import MessageContext from '../../../../contexts/MessageContext'

type TemplateProps = {
  templateParsed: UseTemplate
  onSubmitChange?: (v: boolean) => void
}

const ProviderTemplates: React.FC<TemplateProps> = ({ templateParsed, onSubmitChange }) => {
  const { data: templatesMap, groupedFields, selectedTab, tabNames, updateTemplates, switchTab } = templateParsed
  const addMessage = useContext(MessageContext)
  const [cursorPositions, setCursorPositions] = useState<Partial<Record<string, number>>>({})
  const formik: FormikProps<Record<string, Template>> = useFormik<Record<string, Template>>({
    initialValues: { ...templatesMap },
    onSubmit: (values: Record<string, Template>, helpers: FormikHelpers<Record<string, Template>>): any => {
      helpers.setSubmitting(true)

      // extract changed templates
      const changedTemplates: TemplatesUpdateData = []

      for (const [templateKey, templateValue] of Object.entries(values)) {
        const currentValueText = templateValue.value
        const initialValueText = formik.initialValues[templateKey].value

        if (currentValueText !== initialValueText) {
          // if the value was changed, add it to the list of changed templates
          changedTemplates.push({
            key: templateKey,
            value: currentValueText
          })
        }
      }

      // if no templates were changed, do nothing
      if (changedTemplates.length === 0) {
        helpers.setSubmitting(false)
        return
      }

      // update templates in bulk
      return updateTemplates
        .mutateAsync(changedTemplates)
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data.message ?? err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  // updating parent component about submitting status
  useEffect(() => {
    if (onSubmitChange) {
      onSubmitChange(formik.isSubmitting)
    }
  }, [formik.isSubmitting])

  const handleCursorPosition = (key: string, event: React.SyntheticEvent<HTMLTextAreaElement>): void => {
    setCursorPositions(() => ({
      [key]: (event.target as any).selectionStart
    }))
  }

  const insertValueAtCursor = (key: string, value: string): void => {
    // adding variable to the text
    const currentValue = getFieldValue(formik.values[key])
    const cursorPosition = cursorPositions[key] ?? currentValue.length
    const newValue = currentValue.slice(0, cursorPosition) + value + currentValue.slice(cursorPosition)
    formik.setFieldValue(`${key}.value`, newValue)

    // re-focusing the TextField and set cursor position after insertion
    setTimeout(() => {
      const textField = document.querySelector(`[name="${key}.value"]`) as HTMLInputElement
      if (textField) {
        textField.focus()
        const newPosition = cursorPosition + value.length
        textField.setSelectionRange(newPosition, newPosition)
      }
    }, 0)
  }

  const getFieldValue = (fieldData: Template): string => fieldData.value || fieldData.default_value

  return (
    <form onSubmit={(e) => formik.handleSubmit(e)} style={{ width: '100%' }}>
      <Box display="flex" paddingBlock={4} paddingTop={1}>
        <Stack flex={4}>
          <Box display="flex" justifyContent="space-between" marginTop={1}>
            <Tabs value={selectedTab} onChange={(_, newValue) => switchTab(newValue)} aria-label="Tabs" variant="scrollable" scrollButtons="auto">
              {tabNames.map((name, index) => (
                <Tab key={index} label={name} />
              ))}
            </Tabs>
          </Box>

          {groupedFields?.length ? (
            groupedFields?.map((data) => {
              if (!data.fields.length) {
                return null
              }

              return (
                <Stack key={data.group.name} borderColor={data.group.color} marginTop={2} className={cls(css.templateFieldGroup, { [css.defaultFieldGroup]: data.group?.isDefault })}>
                  <Typography position={'absolute'} bgcolor={data.group.color} className={css.templateFieldGroupTitle}>
                    {data.group.name}
                  </Typography>
                  <Stack>
                    {data.fields.map((key) => {
                      const fieldValue = getFieldValue(formik.values[key])
                      return (
                        <Stack key={key} marginBottom={2}>
                          <Box display="flex" marginBottom={1} gap={0.5} flexWrap="wrap">
                            {formik.values[key].placeholders.map((p) => {
                              const pVar = `{${p}}`
                              return (
                                <Typography className={css.templateVariable + (fieldValue.includes(pVar) ? '' : ' ' + css.templateVariableActive)} key={pVar} variant={'caption'} onClick={() => insertValueAtCursor(key, pVar)}>
                                  {pVar}
                                </Typography>
                              )
                            })}
                          </Box>
                          <TextField
                            multiline
                            fullWidth
                            id={key}
                            name={`${key}.value`}
                            label={key.toUpperCase()}
                            value={fieldValue}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onSelect={(event: React.SyntheticEvent<any>) => handleCursorPosition(key, event)}
                            error={!!formik.touched?.[key]?.value && !!formik.errors?.[key]?.value}
                            helperText={!!formik.touched?.[key]?.value && formik.errors?.[key]?.value}
                          />
                        </Stack>
                      )
                    })}
                  </Stack>
                </Stack>
              )
            })
          ) : (
            <Box display="flex" justifyContent="center" color="#aaa" marginY={2}>
              Templates not found
            </Box>
          )}
          <TemplateActions dirty={formik.dirty} isSubmitting={formik.isSubmitting} resetValues={formik.handleReset} />
        </Stack>
      </Box>
    </form>
  )
}

export default ProviderTemplates
