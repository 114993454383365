/* eslint-disable @typescript-eslint/ban-types */
import { type RowData } from '@tanstack/react-table'
import { type AxiosError } from 'axios'

export interface ResponseList<T> {
  data: T[]
  total: number
}

export interface TemplateConfig {
  id: number
  key: string
  placeholders: string[]
  default_value: string
  created_at: Date
  updated_at: Date
}

export interface TemplateItem {
  id: number
  template_config_id: number
  provider: AI_PROVIDER
  value: string
  created_at: Date
  updated_at: Date
}

export type Template = TemplateItem & Omit<TemplateConfig, 'id' | 'created_at' | 'updated_at'>
export type TemplatesUpdateItem = {
  key: string
  value: string
}
export type TemplatesUpdateData = TemplatesUpdateItem[]

export enum RequestType {
  IDEA = 'idea',
  IDEAS_IMPROVEMENT = 'ideas_improvement',
  IDEAS_PROMPT_IMPROVEMENT = 'ideas_prompt_improvement',
  IDEAS_PROMPT_FINALIZATION = 'ideas_prompt_finalization',
  IMAGE = 'image',
  POST = 'post',
  TARGET_AUDIENCE = 'target_audience',
  POST_REVIEWING = 'post_reviewing',
  POST_UPDATING = 'post_updating',
  POST_TRANSLATION = 'post_translation',
  POST_IMPROVEMENT = 'post_improvement',
  POST_THUMBNAIL_PROMPT = 'post_thumbnail_prompt',
  FRAME = 'frame',
  FRAME_DESCRIPTION = 'frame_description',
  KEYWORDS = 'keywrods',
  KEYWORDS_STATS = 'keywords_stats',
  IMAGE_PROMPT = 'image_prompt',
  IMAGE_ALT = 'image_alt',
  IMAGE_TITLE = 'image_title',
  IMAGE_FILENAME = 'image_filename',
  TRANSLATION = 'translation',
  DESCRIPTION = 'description',
  FAQ = 'faq',
  TAKEAWAYS = 'takeaways',
  SUMMARY = 'summary',
  GRAMMAR = 'grammar',
  IMPROVEMENT = 'improvement',
  EXTENSION = 'extension',
  REWRITING = 'rewriting',
  REPHRASING = 'rephrasing',
  SHORTENING = 'shortening',
  SIMPLIFYING = 'simplifying',
  PARAPHRASING = 'paraphrasing',
  PERSONALIZING = 'personalizing',
  TONE_CHANGING = 'tone_changing',
  STYLIZING = 'stylizing',
  OPTIMIZATION = 'optimization',
  RANK_RECOMMENDATIONS = 'rank_recommendations',
  POST_V2_SUBTITLES = 'postV2_subtitles',
  POST_V2_NOVELTY = 'postV2_novelty',
  POST_V2_OUTLINES = 'postV2_outlines',
  POST_V2_CONTENT = 'postV2_content',
  PAGE_PURPOSE = 'page_purpose',
  PAGE_DESCRIPTION_MISALIGNMENTS = 'page_description_misalignments',
  PAGE_INTENTS = 'page_intents',
  PAGE_AUDIENCE = 'page_audience',
  PAGE_META_SUGGESTION = 'page_meta_suggestion',
  PAGE_GAPS = 'page_gaps',
  PAGE_GAPS_SUGGESTIONS = 'page_gaps_suggestions',
  PAGE_FINAL_SUGGESTIONS = 'page_final_suggestions',
  PAGE_USE_CONTENT_SUGGESTION = 'page_use_content_suggestion',
  PAGE_USE_META_SUGGESTION = 'page_use_meta_suggestion',
  SCANNING = 'scanning',
  DELETED = 'deleted'
}
export type Content<T> = {
  id: number
  company_id: number
  request_id: number
  content: Record<string, T>
  created_at: Date
  updated_at: Date
  request?: Request<any>
}
export type Request<T> = {
  id: number
  company_id: number
  company: Company
  content: Array<Content<any>>
  type: RequestType
  request: Record<string, T>
  created_at: Date
  updated_at: Date
  plugin_version: string
}
export type Keyword = {
  id: number
  company_id: number
  target: string
  keyword: string
  language: string
  location: string
  values: Array<[string, number]>
  created_at: Date
  updated_at: Date
}
export type Post = {}
export type Image = {}
export enum EventType {
  POST_GENERATED = 'post_generated',
  POST_UPDATED = 'post_updated',
  POST_REVIEWED = 'post_reviewed',

  POST_DRAFT = 'post_draft',
  POST_PUBLISHED = 'post_published',
  POST_SCHEDULED = 'post_scheduled',
  POST_CREATED = 'post_created'
}
export type User = {
  id: number
  name: string | null
  email: string
  phone: string | null
  account_status: 'enabled' | 'disabled'
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
  deleted_at: Date | undefined | null
  role: PERMISSION
  company?: Company
}

export type Config = {
  id: number
  config: {
    VERSION: string
    OPENAI_API_URL: string
    OPENAI_API_KEY: string
    ANTHROPIC_API_KEY: string
    DEFAULT_AI_MODEL: string
    DEFAULT_AI_PROVIDER: AI_PROVIDER
    DEFAULT_AI_TEMPERATURE: number
    CLIPDROP_API_KEY: string
    SEO_REVIEW_TOOLS_API_KEY: string
    KEYWORD_STATS_UPDATE_FREQUENCY: number
    KEYWORD_STATS_UPDATE_FREQUENCY_MANUALLY: number
    DATAFORSEO_API_LOGIN: string
    DATAFORSEO_API_PASSWORD: string
  }
}
export type CompanyManager = {
  id: number
  email: string
  role: ManagementRole
}
export type Company = {
  id: number
  name: string | null
  email: string | null
  vat: string | null
  api_key: string | null
  created_at: Date
  updated_at: Date | undefined | null
  deleted_at: Date | undefined | null
  user?: User
  blogs?: Blog[]
  credit?: CompanyCredit
  credit_renewals?: CompanyCreditRenewal[]
  managers?: Record<ManagementRole, CompanyManager | null>
  cs_stage?: CSStage
  plan?: Plan
  upgrade_credits_request?: UpgradeCreditsRequest
}
export enum AI_PROVIDER {
  OPENAI = 'openai',
  ANTHROPIC = 'anthropic'
}
export type CompanyConfigExtended = CompanyConfig & {
  ai_provider_custom: AI_PROVIDER | undefined | null
  ai_provider_default: AI_PROVIDER
  ai_model_custom: string | undefined | null
  ai_model_default: string
  ai_temperature_custom: number | undefined | null
  ai_temperature_default: number
}

export type CompanyConfig = {
  company_id: number
  ai_provider: AI_PROVIDER
  ai_model: string
  ai_temperature: number
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
}

export type UpgradeCreditsRequest = {
  id: number
  company_id: number
  posts: number
  ideas: number
  email: string
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
}
export type CompanyCredit = {
  id: number
  company_id: number
  ideas_used: number
  ideas_limit: number
  images_limit: number
  images_used: number
  frames_used: number
  frames_limit: number
  posts_used: number
  posts_limit: number
  keywords_used: number
  keywords_limit: number
  keywords_stats_limit: number
  keywords_stats_used: number
  keywords_tracked_limit: number
  keywords_tracked_used: number
  pages_analyzed_limit: number
  pages_analyzed_used: number
  keywords_stats_updated_at: Date | undefined | null
  keywords_stats_updated_at_prev: Date | undefined | null
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
  deleted_at: Date | undefined | null
}
export type CompanyCreditRenewal = {
  id: number
  company_id: number
  credit_type: string
  value: number
  delay: number
  next_renewal_at: Date | undefined | null
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
}
export type CompanyContentHistory = Pick<Company, 'id' | 'name'> & {
  history: Record<string, number>
  total: number
}

export type BlogReportCount = Pick<Blog, 'id' | 'name'> & { report: Record<string, { draft: number; scheduled: number; published: number }> }

export enum ManagementRole {
  CS_MANAGER = 'cs_manager'
}

export enum CSStage {
  ONBOARDING = 'onboarding',
  CUSTOMER_SUCCESS = 'customer_success',
  STUCK = 'stuck',
  FIGHT = 'fight',
  CHURN = 'churn'
}

export enum Plan {
  STARTER = 'starter',
  BASIC = 'basic',
  PRO = 'pro'
}

export type CSManagerStat = {
  id: number
  email: string
  stats: Record<CSStage, number>
}

export type CSManagerContent = {
  id: number
  email: string
  content: Record<Partial<EventType>, number>
}

export type Blog = {
  id: number
  company_id: number
  name: string
  url: string
  plugin_version: string
  created_at: Date | undefined | null
  updated_at: Date | undefined | null
  deleted_at: Date | undefined | null
  posts: Post[]
  images: Image[]
}
export type BlogStats = Record<EventType | 'keywords', number>
export enum ROUTES {
  INDEX = '/',
  LOGIN = '/login',
  CONFIG = '/config',
  USERS = '/users',
  COMPANIES = '/companies',
  BLOGS = '/blogs',
  TEMPLATES = '/templates',
  REQUESTS = '/requests',
  PARAMS = '/:id',
  KEYWORDS = '/keywords',
  CONTENT = '/content',
  REPORTING = '/reporting',
  CS_DASHBOARD = '/cs-dashboard',
  NEW = '/new',
  ALL = '*'
}

export enum PERMISSION {
  ADMIN = 'admin',
  MEMBER = 'member',
  AUTHORIZED = 'authorized',
  OWNER = 'owner'
}

export interface PaginatedData {
  limit?: number
  offset?: number
}

export interface QueryParams<T> extends PaginatedData {
  entity?: Partial<T>
  user_id?: number
  search?: string
  company_id?: number
  type?: RequestType
  request_id?: number
}

export interface ResponseData<T> {
  data: T[]
  total: number
}

export enum CreditType {
  IDEAS_USED = 'ideas_used',
  IDEAS_LIMIT = 'ideas_limit',
  IMAGES_LIMIT = 'images_limit',
  IMAGES_USED = 'images_used',
  FRAMES_USED = 'frames_used',
  FRAMES_LIMIT = 'frames_limit',
  POSTS_USED = 'posts_used',
  POSTS_LIMIT = 'posts_limit',
  KEYWORDS_USED = 'keywords_used',
  KEYWORDS_LIMIT = 'keywords_limit',
  KEYWORDS_STATS_USED = 'keywords_stats_used',
  KEYWORDS_STATS_LIMIT = 'keywords_stats_limit',
  KEYWORDS_TRACKED_USED = 'keywords_tracked_used',
  KEYWORDS_TRACKED_LIMIT = 'keywords_tracked_limit',
  PAGES_ANALYZED_USED = 'pages_analyzed_used',
  PAGES_ANALYZED_LIMIT = 'pages_analyzed_limit',
  KEYWORDS_STATS_UPDATED_AT = 'keywords_stats_updated_at',
  KEYWORDS_STATS_UPDATED_AT_PREV = 'keywords_stats_updated_at_prev'
}
export enum RenewableCreditType {
  KEYWORDS_TRACKED = 'keywords_tracked',
  PAGES_ANALYZED = 'pages_analyzed',
  IMAGES = 'images'
}

export type ObjectValues<T> = T[keyof T]

type BaseErrorResponse = {
  message: string
}

export type LoginInput = {
  email: string
  password: string
}

type TokenPair = {
  api_token: string
  refresh_token: string
}

export type LookerStudioType = {
  id: number
  iframe: string
}

export type Assessment = {
  desktop?: number | string
  mobile?: number | string
}

export type Assessments = {
  performance: Assessment
  accessibility: Assessment
  'best-practices': Assessment
  seo: Assessment
  'first-contentful-paint': Assessment
  'largest-contentful-paint': Assessment
  'total-blocking-time': Assessment
  'cumulative-layout-shift': Assessment
  'speed-index': Assessment
  [key: string]: Assessment | undefined
}

export type AuditLighthouseItem = {
  id: number
  blog_id: number
  user_id: number
  audit_data_desktop_id: number | null
  audit_data_mobile_id: number | null
  url: string
  assessments: Assessments | null
  status: number
  created_at: string | undefined
}

export type BaseError = AxiosError<BaseErrorResponse>

export type LoginResponse = TokenPair
export type LoginError = BaseError
export type RefreshAuthInput = Partial<Pick<TokenPair, 'refresh_token'>> & { api_token: string }
export type RefreshAuthResponse = TokenPair
export type RefreshAuthError = BaseError
